export default {
  // 浏览器 title
  title: ' | ZGCHEN',
  // 网站名
  webName: 'ZGCHEN',

  // 侧边栏风格
  SildeBar: 'hHh lpR fFf', // 风格二：lHh lpR fFf

  // axios 中请求基地址，线上使用了nginx带了所以需要去掉域名
  baseURL: '/api_v1/',

  // 测试的请求地址，线上使用了nginx带了所以需要去掉域名
  baseURLTest: '/mock/api_v1/',

  // 请求超时时间
  timeOut: 8000,

  // 组件最大缓存数
  Max_KeepAlive: 10,
  // 盐
  SIGN_KEY: "es0*E*HP9XR*t@EmMRn=b8fhqUpzKz9T"
};

