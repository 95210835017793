import {defineStore} from 'pinia'
import SessionStorage from 'quasar/src/plugins/SessionStorage.js';;
import {removeATagView, removeOneSide} from '@/components/TagView/TagViewUtils'
import router from '@/router/permissionWithDynamicRouter'

export const useMenuStore = defineStore('menu', {
  state: () => ({
    leftDrawerOpen: true,
    hide: false,
    tagView: [],
    breadcrumbs: [],
    keepAliveList: [],
    menuSearchData: []
  }),
  getters: {},
  actions: {
    setMenuSearchData(payload) {
      this.menuSearchData = payload
    },
    removeTagView(payload) {
      switch (typeof payload) {
        case 'undefined':
          this.tagView = []
          SessionStorage.set('tagView', [])
          router.push('/')
          break
        case 'object':
          removeOneSide(payload)
          break
        default:
          removeATagView(payload)
      }
    },
    setKeepAliveList(payload) {
      this.keepAliveList = []
      for (let i = 0; i < payload.length; i++) {
        if (payload[i].keepAlive) {
          this.keepAliveList.push(payload[i].name)
        }
      }
      // 如果需要缓存首页，如下方所示，在方法最后 push 对应的路由组件名称即可
      // this.keepAliveList.push('NewHome')
    },
    addTagView(payload) {
      const size = this.tagView.length
      // 首次进入或刷新页面时，当前路由不是根路由
      if (!size && payload.fullPath !== '/') {
        this.tagView.push(payload)
        if (this.keepAliveList.indexOf(payload.name) === -1 && payload.keepAlive === true) this.keepAliveList.push(payload.name)
        return
      }
      // 为了避免 tagView 重复添加。 构建一个以 fullPath 为标识的数组 t[]
      const t = []
      for (let i = 0; i < size; i++) {
        t.push(this.tagView[i].fullPath)
      }
      if (t.indexOf(payload.fullPath) === -1) {
        this.tagView.push(payload)
        if (this.keepAliveList.indexOf(payload.name) === -1 && payload.keepAlive === true) this.keepAliveList.push(payload.name)
      }
      // 在这设置缓存，原来是在组件里watch的
      SessionStorage.set('tagView', this.tagView)
    },
    setTagView(payload) {
      this.tagView = payload
    },
    setBreadcrumbs(bread) {
      this.breadcrumbs = bread
    },
    toggleLeftDrawer() {
      this.leftDrawerOpen = !this.leftDrawerOpen
    },
    changeHide() {
      this.hide = !this.hide
    },
  }
})

