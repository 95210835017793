import {md5} from "js-md5";
import config from "@/config";
import SessionStorage from 'quasar/src/plugins/SessionStorage.js';import Notify from 'quasar/src/plugins/Notify.js';;


function createSign(verify, tp, tk) {
  let key = config.SIGN_KEY
  let text = verify + "^0^" + tp + key + "^_^" + tk
  return md5(text)
}

function getBaseUrl() {
  const userInfo = SessionStorage.getItem('userInfo')

  if (userInfo && userInfo.identity === 'Test') {
    return config.baseURLTest;
  }
  return config.baseURL
}

function getFormattedDate() {
  const currentDate = new Date();

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const hours = currentDate.getHours().toString().padStart(2, '0');
  const minutes = currentDate.getMinutes().toString().padStart(2, '0');
  const seconds = currentDate.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function customNotify(msg, color = 'green', position = 'top', timeout = 1000) {
  Notify.create({
    icon: color === 'green' ? 'mood' : 'mood_bad',
    message: msg,
    color: color,
    position: position,
    timeout: timeout,
    progress: true
  })
}

export {createSign, getBaseUrl, getFormattedDate, customNotify}
