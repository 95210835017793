<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script setup>

</script>
<style>
@import "./assets/css/main.css";
@import './assets/scss/transition.scss';
@import "./assets/css/custom.css";
</style>
