import {defineStore} from "pinia";


export const useUserStore = defineStore('user', {
  state: () => ({
    userInfo: {},
    role: '',
    routes: [],
    editMarkdown: ''
  }),
  getters: {},
  actions: {
    setUserInfo(payload) {
      this.userInfo = payload
    },
    setRoutes(payload) {
      this.routes = payload
    },
    setEditMarkdown(payload) {
      this.editMarkdown = payload
    }
  }
})
