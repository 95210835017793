import pinia from "@/store";
import {useMenuStore} from "@/store/menuStore";
import SessionStorage from 'quasar/src/plugins/SessionStorage.js';
import router from '../../router'
import {getFirst} from '@/utils/CloneUtils'

// 构造 tag-view 的元信息，如果符合条件( 不是公共路由 )就提交到 store，生成 tagView 元素
export function addTagView(to, menuStore) {
  // const menuStore = useMenuStore(pinia)
  // 设置缓存列表暂时不管
  SessionStorage.set('tagView', menuStore.tagView)
  // 构造临时 tagView 对象
  const t = {
    fullPath: to.fullPath,
    name: to.name,
    title: to.meta.title,
    icon: to.meta.icon,
    keepAlive: to.meta.keepAlive || false
  }
  getFirst(to.query) !== undefined && (t.title += '：' + getFirst(to.query))

  if (t.title !== null && t.title !== undefined && t.fullPath !== '/' && t.fullPath.indexOf('#') === -1) {
    // 添加到store中
    menuStore.addTagView(t)
  }
}

export function setTagView(tagView, menuStore) {
  // const menuStore = useMenuStore(pinia)
  menuStore.setTagView(tagView)

}

// 只移除一个 tagView
export function removeATagView(removeTagIndex) {
  const menuStore = useMenuStore(pinia)
  // 记录被移除的路由
  const removedTagView = menuStore.tagView[removeTagIndex].fullPath
  menuStore.tagView.splice(removeTagIndex, 1)
  // 如果移除后， tagView 为空
  if (Object.keys(menuStore.tagView).length === 0) {
    SessionStorage.set('tagView', [])
    router.push('/')
  } else {
    // 如果移除的是最后一个 tagView 则路由跳转移除后的最后一个 tagView
    if (removeTagIndex === Object.keys(menuStore.tagView).length && window.location.href.indexOf(removedTagView) !== -1) {
      router.push(menuStore.tagView[removeTagIndex - 1].fullPath)
      return
    }
    // 如果移除的是第一个 tagView 则路由跳转移除后的第一个 tagView
    if (removeTagIndex === 0 && window.location.href.indexOf(removedTagView) !== -1) {
      router.push(menuStore.tagView[0].fullPath)
      return
    }
    if (window.location.href.indexOf(removedTagView) !== -1) {
      router.push(menuStore.tagView[removeTagIndex - 1].fullPath)
    }
  }
}

// 移除某一侧 tagView
export function removeOneSide(payload) {
  const menuStore = useMenuStore(pinia)
  switch (payload.side) {
    case 'right':
      menuStore.tagView = menuStore.tagView.slice(0, payload.index + 1)
      if (Object.keys(menuStore.tagView).length === 1) {
        router.push(menuStore.tagView[0].fullPath)
      }
      if (Object.keys(menuStore.tagView).length === payload.index + 1) {
        router.push(menuStore.tagView[payload.index].fullPath)
      }
      break
    case 'left':
      menuStore.tagView = menuStore.tagView.slice(payload.index, Object.keys(menuStore.tagView).length)
      if (Object.keys(menuStore.tagView).length === 1) {
        router.push(menuStore.tagView[0].fullPath)
      }
      if (Object.keys(menuStore.tagView).length <= payload.index) {
        router.push(menuStore.tagView[0].fullPath)
      }
      break
    case 'others':
      menuStore.tagView = menuStore.tagView.splice(payload.index, 1)
      router.push(menuStore.tagView[0].fullPath)
      break
    default:
      break
  }
}
