import { createRouter, createWebHistory } from 'vue-router';
import constantRoutes from './constantRoutes';

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes
});

// 解决路由守卫重定向时报错
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch(err => err);
};

// 重置路由方法
export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(),
    routes: []
  });
  router.matcher = newRouter.matcher;
}

export default router;
