import deepClone, {getFirst} from '@/utils/CloneUtils'

export function setBreadcrumbs(matched, query, menuStore) {
  const temp = []
  for (let i = 0; i < matched.length; i++) {
    temp.push(deepClone(matched[i].meta))
  }
  const last = temp.length - 1
  // 如果有 query 则取第一个参数附加在 title 上
  Object.keys(query).length && (temp[last].title += '：' + getFirst(query))
  menuStore.setBreadcrumbs(temp)
}
