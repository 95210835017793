import request from '@/axios/AxiosConfig'


export function getUserLogin(user, pwd) {
  return request({
    url: 'userInfos/userLogon',
    method: 'post',
    type: 'FORM',
    data: {"username": user, "password": pwd}
  })
}

export function getBaseRouter() {
  return request({
    url: 'userInfos/baseRoutes',
    method: 'get',
  })
}

export function getUserRouter() {
  return request({
    url: 'userInfos/userRoutes',
    type: 'FORM',
    method: 'post'
  })
}

export function getAllUsers() {
  return request({
    url: 'userInfos/allUsers',
    method: 'get'
  })
}


export function getSearchUser(keyword, option="name") {
  return request({
    url: 'userInfos/searchUsers',
    type: 'FORM',
    method: 'post',
    data: {keyword: keyword, option: option}
  })
}

export function addUser(userInfos) {
  return request({
    url: 'userInfos/addUser',
    method: 'post',
    type: 'FORM',
    data: {userInfo: userInfos}
  })
}


export function deleteUser(userId) {
  return request({
    url: 'userInfos/deleteUser',
    method: 'post',
    type: 'FORM',
    data: {userId: userId}
  })
}

export function updateUser(userInfo) {
  return request({
    url: 'userInfos/updateUser',
    method: 'post',
    type: 'FORM',
    data: {userInfo: JSON.stringify(userInfo)}
  })
}

export function userUpdateUser(userInfo) {
  return request({
    url: 'userInfos/userUpdateUser',
    method: 'post',
    type: 'FORM',
    data: {userInfo: JSON.stringify(userInfo)}
  })
}

export function modifyUserRoute(userRoute) {
  // 修改好的用户信息带路由
  return request({
    url: 'userInfos/updateRoute',
    method: 'post',
    type: 'FORM',
    data: {userRoute: JSON.stringify(userRoute)}
  })
}



