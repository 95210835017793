import {createApp} from 'vue'
import App from './App.vue'
import router from './router/permissionWithDynamicRouter'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import pinia from "@/store";
import config from './config'
import ECharts from 'vue-echarts';


const app = createApp(App);

app.config.errorHandler = () => {
  // 参数：err, vm, info
};

app.provide('config', config)
app.use(Quasar, quasarUserOptions)
app.use(pinia)
app.use(router)
app.component('v-chart', ECharts);

app.mount('#app')

