import router from './index'
import constantRoutes from './constantRoutes'
import LoadingBar from 'quasar/src/plugins/LoadingBar.js';import SessionStorage from 'quasar/src/plugins/SessionStorage.js';
import {addTagView, setTagView} from '@/components/TagView/TagViewUtils'
import {setBreadcrumbs} from '@/components/MenuBreadcrumbs/BreadcrumbsUtils'
import {getUserRouter} from '@/api/UserApi'
import {handleJsonRouterToAsyncRouter} from './permissionUtils'
import asyncRoutes from './asyncRoutes'
import app from '@/config'
import pinia from "@/store";
import {useMenuStore} from "@/store/menuStore"
import {useUserStore} from "@/store/userStore";

const view404 = {
  path: '/:catchAll(.*)',
  name: '404',
  redirect: '/NoFound404',
  meta: {
    title: '404',
    icon: 'fab fa-studiovinari',
    isHidden: true
  }
}


router.beforeEach(async (to, from, next) => {

  const menuStore = useMenuStore(pinia)
  const userStore = useUserStore(pinia)

  // 成功登录后处理 TAGVIEW 和 面包屑
  handleTagViewAndBreadcrumbsAndKeepAlive(to, menuStore, userStore)
  const token = SessionStorage.getItem('token')
  // 存在 token 说明已经登录
  if (token) {
    // 登录过就不能访问登录界面，需要中断这一次路由守卫，执行下一次路由守卫，并且下一次守卫的to是主页'
    if (to.path === '/logon') {
      next({path: '/'})
    }
    // 动态路由不需要保存用户角色了，判断 store 中路由不为空则放行即可
    if (Object.keys(userStore.routes).length) {
      //放行
      next()
    } else {
      // 不存在用户权限时，从后台获取用户对应的路由
      const userRouterResponse = await getUserRouter()
      // 将从后台获取的路由转换为 vue-router 可用形式
      const userRouter = handleJsonRouterToAsyncRouter(userRouterResponse.result)
      // 设置菜单搜索数据
      menuSearchDataHandle(userRouter)
      // 在路由的末尾处添加 404 界面
      userRouter.push(view404)
      // 将后台请求到的路由加入到主布局路由中
      asyncRoutes[0].children = userRouter
      // 将路由设置到 store 中
      userStore.setRoutes(asyncRoutes)
      // 动态添加路由
      asyncRoutes.forEach((route) => {
        router.addRoute(route)
      })
      // 如果 addRoutes 并未完成，路由守卫会一层一层的执行执行，直到 addRoutes 完成，找到对应的路由
      next({...to, replace: true})
    }
  } else {
    // 未登录时，注意 ：在这里也许你的项目不只有 logon 不需要登录 ，register 等其他不需要登录的页面也需要处理
    if (to.path !== '/logon') {
      next({path: '/logon'})
    } else {
      next()
    }
  }
})

router.afterEach(() => {
  LoadingBar.stop()
  LoadingBar.stop()
})

export default router

function menuSearchDataHandle(data) {
  const menuStore = useMenuStore(pinia)
  const flatArray = [];

  function traverse(arr, parentPath = '') {
    for (const item of arr) {
      let fullPath = `${parentPath}/${item.path}`.replace(/\/+/g, '/');

      if (fullPath.startsWith('//')) {
        fullPath = fullPath.substring(1);
      }

      if (item.component && item.component.__file && item.component.__file.endsWith('MenuLayout.vue')) {
        if (item.children && Array.isArray(item.children) && item.children.length > 0) {
          traverse(item.children, fullPath);
        }
      } else {
        if (item.meta && item.meta.title && !item.path.endsWith('MenuLayout.vue') && item.meta.title !== '详情' && item.meta.title !== '404') {
          const newItem = {name: item.meta.title, path: fullPath, icon: item.meta.icon};
          flatArray.push(newItem);
        }

        if (item.children && Array.isArray(item.children) && item.children.length > 0) {
          traverse(item.children, fullPath);
        }
      }
    }
  }

  traverse(data);
  menuStore.setMenuSearchData(flatArray)
}


function handleTagViewAndBreadcrumbsAndKeepAlive(to) {
  const menuStore = useMenuStore(pinia)
  const userStore = useUserStore(pinia)
  if (to.name != null) {
    document.title = to.meta.title + app.title
    LoadingBar.start()
    // 判断要添加的 to 是否是公共路由
    for (let i = 0; i < constantRoutes.length; i++) {
      if (constantRoutes[i].path === to.path) {
        return
      }
    }
    // 判断是否为刷新操作，如果是刷新操作则从 sessionStorage 获取保存的 tagView 信息
    let tagViewOnSS = []
    SessionStorage.getItem('userInfo') === null ? SessionStorage.set('userInfo', {}) : userStore.setUserInfo(SessionStorage.getItem('userInfo'))
    SessionStorage.getItem('tagView') === null ? SessionStorage.set('tagView', []) : tagViewOnSS = SessionStorage.getItem('tagView')
    if (Object.keys(menuStore.tagView).length === 0 && tagViewOnSS.length !== 0) {
      setTagView(tagViewOnSS, menuStore)
      menuStore.setKeepAliveList(tagViewOnSS)
    } else {
      addTagView(to, menuStore)
    }
    setBreadcrumbs(to.matched, to.query, menuStore)
    handleKeepAlive(to)
  }
}

/**
 * 处理多余的 layout : router-view，让当前组件保持在第一层 index : router-view 之下
 * 这个方法无法过滤用来做嵌套路由的按需加载的 <layout>
 * @param to
 */
function handleKeepAlive(to) {
  if (to.matched && to.matched.length > 2) {
    for (let i = 0; i < to.matched.length; i++) {
      const element = to.matched[i]
      // if (element.components.default.name === 'MenuLayout') { // 老版本
      if (element.components.default.__file && element.components.default.__file.endsWith('MenuLayout.vue')) {
        to.matched.splice(i, 1)
        handleKeepAlive(to)
      }
    }
  }
}

/**
 * 这个方法可以过滤用来做嵌套路由的按需加载的 <layout>
 * @param to
 */
// async function handleKeepAlive (to) {
//   if (to.matched && to.matched.length > 2) {
//     for (let i = 0; i < to.matched.length; i++) {
//       const element = to.matched[i]
//       if (element.components.default.name === 'MenuLayout') {
//         to.matched.splice(i, 1)
//         await handleKeepAlive(to)
//       }
//       if (typeof element.components.default === 'function') {
//         await element.components.default()
//         await handleKeepAlive(to)
//       }
//     }
//   }
// }
