import Notify from 'quasar/src/plugins/Notify.js';import SessionStorage from 'quasar/src/plugins/SessionStorage.js';;
import Axios from 'axios';
import qs from 'qs';
import _config from "@/config"
import {useMenuStore} from "@/store/menuStore"
import {useUserStore} from "@/store/userStore";
import router from '@/router/permissionWithDynamicRouter'
import {createSign, getBaseUrl} from "@/utils/public";


/**
 * axios 初始化
 */
const baseURL = _config.baseURL
const timeOut = _config.timeOut


const axios = Axios.create({
  baseURL, // 使用 baseURL 变量
  timeout: timeOut // 使用 timeOut 变量
});


axios.interceptors.request.use(
  config => {
    const menuStore = useMenuStore()
    const userStore = useUserStore()
    const nowtime = new Date().getTime()
    const exp = SessionStorage.getItem('tokenExt')

    config.baseURL = getBaseUrl()
    if (nowtime < exp || config.url.includes('userInfos/userLogon')) {
      // 不是登陆就需要token等信息
      if (!config.url.includes('userInfos/userLogon')) {
        const token = SessionStorage.getItem('token')
        const userInfo = SessionStorage.getItem('userInfo')
        const tp = new Date().getTime()
        config.headers.authorization = token
        config.headers.verify = userInfo.userId
        config.headers.tp = tp
        config.headers.sg = createSign(userInfo.userId, tp, token)
      }
      if (config.type) {
        switch (config.type) {
          case 'FORM-DATA':
            config.transformRequest = [data => {
              return 'args=' + JSON.stringify(data)
            }]
            break
          case 'FORM':
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            config.data = qs.stringify(config.data)
            break
          default:
            break
        }
      }
      return config
    } else {
      Notify.create({
        message: '登录已过期, 请重新登录',
        icon: 'warning',
        color: 'red',
        position: 'top',
        timeout: 1500
      })
      SessionStorage.clear()
      menuStore.$reset()
      userStore.$reset()
      router.push('/logon')
      return config
    }
  },
  error => {
    return Promise.reject(error)
  }
)


// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.verify) return response.data
    else {
      return Promise.reject(new Error('Verification failed'))
    }
  },
  error => {
    const defaultNotify = {
      message: '未知错误',
      icon: 'warning',
      color: 'warning',
      position: 'top',
      timeout: 1500,
      progress: true
    }
    if (error.code === 'ECONNABORTED' || error.message.indexOf('timeout') !== -1 || error.message === 'Network Error') {
      defaultNotify.message = '网络异常'
      Notify.create(defaultNotify)
      return Promise.reject(error)
    }
    switch (error.response.status) {
      case 401:
        defaultNotify.message = '没有权限(401)'
        Notify.create(defaultNotify)
        break
      case 403:
        defaultNotify.message = '拒绝访问(403)'
        Notify.create(defaultNotify)
        break
      case 404:
        defaultNotify.message = '资源不存在(404)'
        Notify.create(defaultNotify)
        break
      case 408:
        defaultNotify.message = '请求超时(408)'
        Notify.create(defaultNotify)
        break
      case 500:
        defaultNotify.message = '服务器错误(500)'
        Notify.create(defaultNotify)
        break
      case 501:
        defaultNotify.message = '服务未实现(501)'
        Notify.create(defaultNotify)
        break
      case 502:
        defaultNotify.message = '网络错误(502)'
        Notify.create(defaultNotify)
        break
      case 503:
        defaultNotify.message = '服务不可用(503)'
        Notify.create(defaultNotify)
        break
      case 504:
        defaultNotify.message = '网络超时(504)'
        Notify.create(defaultNotify)
        break
      case 505:
        defaultNotify.message = 'HTTP版本不受支持(505)'
        Notify.create(defaultNotify)
        break
      default:
        break
    }
    return Promise.reject(error)
  }
)

export default axios
