
/**
 * 需要授权访问的路由
 */
const asyncRoutesChildren = []

const asyncRoutes = [
  {
    path: '/',
    name: 'index',
    redirect: '/',
    component: () => import('@/views/NewIndex.vue'),
    children: asyncRoutesChildren
  }
]

export default asyncRoutes

export {asyncRoutesChildren}
